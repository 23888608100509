/* Table of Contents: 
  1. UNIVERSAL
  2. GLOBAL
  3. SCROLLBARS
  4. HEADER / NAVBAR STYLES START
  
  */

/* 1. UNIVERSAL */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

*::selection {
  background-color: transparent;
}

/* 2. GLOBAL */

:root {
  --background: #000;
  --dark: #525050;
  --darker: #383737;
  --darkest: #202020;
  --main-text: #EEE3D0;
  --button: #386472;
  --cancel: #a23e25;
  --primary-font: 'Kodchasan', sans-serif;
  --secondary-font: 'Bungee Hairline', cursive;
}

html,
body {
  min-height: 100%;
  overflow-x: hidden;
  background-color: var(--background);
}

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #525050; */
  color: var(--main-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--main-text);
  text-decoration: none;
}

.display-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#home-wrapper {
  display: flex;
  justify-content: center;
}

/* SCROLLBAR STYLES START */
.styled-scrollbars {
  scrollbar-color: var(--darker) var(--dark)
}

.styled-scrollbars::-webkit-scrollbar {
  background-color: var(--dark);
  width: 10px;
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.styled-scrollbars::-webkit-scrollbar-thumb {
  background-color: var(--darker);
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.page-wrapper {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
}

/* HEADER / NAVBAR STYLES START */

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 0 1vh 10vw;
  font-family: 'Montserrat', sans-serif;
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 12vh;
  opacity: .90;
  background-color: var(--darkest);
}

.title-wrapper {
  width: 217px;
}

#title-mobile {
  display: none;
}

header h1 {
  /* font-family: 'Zen Dots', cursive; */
  /* font-family: 'Orbitron', sans-serif; */
  /* font-family: 'Audiowide', cursive; */
  /* font-family: 'Krona One', sans-serif; */
  font-family: 'Kodchasan', sans-serif;
  letter-spacing: 5px;
  font-weight: 100;
}

#cube-icon {
  width: 40px;
}

header nav ul {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  width: 50vw;
  padding: 2vh 9vw 2vh 0;
}

header nav ul li a {
  font-family: 'Bungee Hairline', cursive;
  letter-spacing: 3px;
  font-size: 20px;
  font-weight: bold;
}

/* HEADER / NAV STYLES END */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background);
  padding-top: 24vh;
}

.wrapperOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background);
  padding-top: 35vh;
}

.utility-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 12vh;
  margin-top: 12vh;
  z-index: 9999;
  position: fixed;
  opacity: .90;
  background-color: var(--background);
}

.datePicker {
  background-color: var(--darkest);
  font-family: var(--primary-font);
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 1.17em;
  color: var(--main-text);
  border: none;
  border-radius: 8px;
  padding: 5px 0;
  text-align: center;
}

.react-datepicker__input-container input:focus-visible {
  outline: none;
}


.react-datepicker-wrapper {
  text-align: center;
}

.calendar {
  background-color: var(--darkest) !important;
  border: var(--main-text) 1px solid !important;
}

.calendar .react-datepicker__header {
  background-color: var(--main-text);
}

.calendar .react-datepicker__navigation-icon::before {
  border-color: var(--darkest);
}

.calendar .react-datepicker__navigation:hover .react-datepicker__navigation-icon::before {
  border-color: var(--dark);
}

.calendar .react-datepicker__triangle::after {
  border-bottom-color: var(--main-text) !important;
}

.calendar .react-datepicker__day {
  color: var(--main-text);
}

.calendar .react-datepicker__day--disabled {
  color: var(--dark)
}

.calendar .react-datepicker__day--keyboard-selected {
  background-color: var(--dark);
}

.calendar .react-datepicker__day--selected {
  background-color: var(--button);
  color: var(--main-text);
}

.calendar .react-datepicker__day:hover {
  background-color: var(--dark);
  color: var(--main-text);
}

.options {
  margin-right: .5vw;
  width: 50px;
  height: auto;
}

.options:hover {
  cursor: pointer;
}

.sortFilterContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  /* height: 6vh; */
  margin-top: 24vh;
  position: fixed;
  opacity: .90;
  background-color: var(--background);
  border-top: 1px solid var(--darkest);
  border-bottom: 1px solid var(--darkest);
}

.sort-buttons {
  flex: 0 0 30%;
  margin: 10px 0;
  /* padding: 3px 10px; */
  padding: 10px 0;
  border-radius: 8px;
  background-color: var(--darkest);
  border: none;
  color: var(--main-text);
  /* font-family: var(--secondary-font); */
  letter-spacing: 3px;
  /* font-weight: bolder; */
  font-size: 1.17em;
}

.sort-buttons:hover {
  cursor: pointer;
}

.sortIcon {
  width: 20px;
  height: auto;
}

/* .venue-az {
  
} */

.home-page-wrapper {
  width: 100vw;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background);
  gap: 2vh;
}

/* HOME STYLES START */
main {
  width: 100vw;
  min-height: 100vh;
}

.events {
  margin-bottom: 2vh;
}

.date-wrapper {
  width: 26%;
  font-family: var(--primary-font);
}

#date {
  font-family: var(--primary-font);
  letter-spacing: 3px;
  font-weight: 700;
}

.arrows {
  width: 50px;
}

.arrows:hover {
  cursor: pointer;
}

.disabled-arrows {
  width: 50px;
  color: grey;
}

/* SPINNER STYLES */
.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

/* SHOWCARD COMPONENT && SHOW PAGE STYLES START */
.back-button-wrapper {
  width: 90vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.back-button {
  color: var(--main-text);
  width: 50px;
}

.back-button:hover {
  cursor: pointer;
}

#concert-list-show-card {
  width: 90vw;
}

.show-card {
  background-color: var(--darkest);
  /* margin-bottom: 4vh; */
  border-radius: 10px;
  border: 1px solid #386472;
  display: flex;
  align-items: center;
  justify-content: center;
}

#show-card-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

#show-card-left-contents {
  display: flex;
  align-items: center;
  gap: 2vw;
}

#admin-delete:hover {
  cursor: pointer;
}

#admin-buttons {
  display: flex;
  height: auto;
  width: 100%;
}

.testyclass {
  background-color: var(--main-text);
}

#show-card-data {
  /* text-align: center; */
  padding: 3vh 2vw;
  font-size: 20px;
}

/* #show-card-venue-list-data {
  width: 30vw;
  text-align: center;
  padding: 3vh 2vw;
  font-size: 20px;
} */

#venue-show-list-data {
  width: 80vw;
  padding: 3vh 2vw;
}

.show-header-wrapper {
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2vh;
}

.show-wrapper {
  width: 90vw;
  min-height: 42vh;
  padding: 3vh;
}

.show-wrapper p {
  margin: 2vh 0;
}

.show-links {
  list-style: none;
}

.show-links li {
  margin: 2vh 0;
}

.disabled-icons {
  width: 50px;
  color: var(--dark);
}

.plus-sign {
  width: 50px;
  color: var(--button);
  visibility: visible;
}

.plus-sign:hover {
  cursor: pointer;
}

.minus-sign {
  width: 50px;
  color: var(--cancel);
  /* visibility: hidden; */
}

.minus-sign:hover {
  cursor: pointer;
}

#plus-sign-logged-out {
  width: 50px;
  color: var(--darker);
  margin-left: 2vw;
}


/* #artists-link {
  font-size: 22px;
  font-weight: bold;
} */

#address2 {
  margin-top: -2vh;
}

/* RSVP STYLES START */

.rsvp-container {
  width: 90%;
  display: flex;
  justify-content: space-around;
  margin-top: 4vh;
}

.rsvp-wrapper {
  display: flex;
  align-items: center;
}

.rsvp-counts {
  padding-left: 2vh;
}

.rsvp-yes {
  width: 50px;
  color: #386472
}

.rsvp-yes:hover {
  cursor: pointer;
}

.rsvp-no {
  width: 50px;
  color: var(--cancel)
}

.rsvp-no:hover {
  cursor: pointer;
}

.rsvp-maybe {
  width: 50px;
  /* color:#DFBB89; */
  color: #DFDEC5;
}

.rsvp-maybe:hover {
  cursor: pointer;
}

/* LOGIN STYLES START */
.container {
  width: 100vw;
  min-height: 92vh;
  padding-top: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background);
}

.form-card {
  background-color: var(--darkest);
  border-radius: 10px;
  border: 1px solid #386472;
}

/* .form-card form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* .form-card p {
  margin-top: 4vh;
  font-family: var(--primary-font);
  font-size: 25px;
  letter-spacing: 5px;
} */

/* .form-card div {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2vh 8vh 0 8vh;
  font-family: var(--secondary-font);
} */

/* .form-div {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2vh 8vh 0 8vh;
  font-family: var(--secondary-font);
} */

/* .form-card label {
  font-family: var(--secondary-font);
  letter-spacing: 3px;
  font-weight: bold;
} */

/* .form-card input {
  width: 100%;
  border-radius: 8px;
  padding: 5px 0 5px 5px;
} */

/* .login-signup-button {
  margin: 4vh 0;
  padding: 3px 0;
  width: 75%;
  background-color: var(--button);
  border: none;
  border-radius: 10px;
  color: var(--main-text);
  font-family: var(--secondary-font);
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 20px;
}

.login-signup-button:hover {
  cursor: pointer;
} */

/* .form-card-button {
  margin: 4vh 0;
  padding: 3px 0;
  width: 75%;
  background-color: var(--button);
  border: none;
  border-radius: 10px;
  color: var(--main-text);
  font-family: var(--secondary-font);
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 20px;
}

.form-card-button:hover {
  cursor: pointer;
} */

/* #form-block-button {
  margin: 4vh 0;
  padding: 3px 0;
  width: 100%;
  background-color: var(--cancel);
  border: none;
  border-radius: 10px;
  color: var(--main-text);
  font-family: var(--secondary-font);
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 20px;
}

#form-block-button:hover {
  cursor: pointer;
} */

/* .already-sent-blocked-button {
  margin: 4vh 0;
  padding: 3px 0;
  width: 100%;
  background-color: var(--dark);
  border: none;
  border-radius: 10px;
  color: var(--main-text);
  font-family: var(--secondary-font);
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 20px;
  display: flex;
  justify-content: center;
} */

/* PROFILE STYLES START */
/* .profile-page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
  width: 100%;
  min-height: 10vh;
} */

/* .profile-page-wrapper {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--background);
} */
.profile-page-wrapper {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background);
}

.concert-friend-wrapper {
  width: 100%;
  min-height: 92vh;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.profile-concerts-card {
  width: 60vw;
  min-height: 80vh;
}

.profile-concerts-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 0 2vh 2vh;
  margin-bottom: 2vh;
  border-bottom: 2px solid var(--dark);
  letter-spacing: 2px;
  font-size: 20px;
}

#expander {
  display: none;
}

/* #profile-showcard-data {
  padding: 3vh 0 3vh 30px;
  width: 90%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

#profile-showcard-data button {
  margin-top: 2vh;
  width: 150px;
  background-color: var(--button);
  border: none;
  border-radius: 10px;
  color: var(--main-text);
  font-family: var(--secondary-font);
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 16px;
  padding: 3px;
}

#profile-showcard-data button:hover {
  cursor: pointer;
} */

/* FRIEND STYLES START */
/* .profile-friends-card {
  width: 30vw;
  min-height: 80vh;
} */

/* .profile-friends-card-header {
  padding: 2vh 0 2vh 2vh;
  margin-bottom: 2vh;
  border-bottom: 2px solid var(--dark);
  letter-spacing: 2px;
  font-size: 20px;
} */

/* .profile-friends-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.unSwitched {
  color: var(--darkest);
}

/* .profile-friends-card form {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

/* .profile-friends-card form div {
  width: 65%;
  display: flex;
  justify-content: center;
} */

/* .form-input-wrapper {
  width: 75%;
  display: flex;
  justify-content: center;
} */

/* .profile-friends-card-input {
  width: 100%;
  border-radius: 8px;
  padding: 5px 0 5px 5px;
  margin-top: 4vh;
} */

/* .profile-friends-card form div input {
  width: 100%;
  border-radius: 8px;
  padding: 5px 0 5px 5px;
  margin-top: 4vh;
} */

/* .form-request-button {
  width: 100%;
  margin: 4vh 0;
  padding: 3px 0;
  background-color: var(--button);
  border: none;
  border-radius: 10px;
  color: var(--main-text);
  font-family: var(--secondary-font);
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 20px;
}

.form-request-button:hover {
  cursor: pointer;
} */

/* .profile-friends-card form div button {
  width: 100%;
  margin: 4vh 0;
  padding: 3px 0;
  background-color: var(--button);
  border: none;
  border-radius: 10px;
  color: var(--main-text);
  font-family: var(--secondary-font);
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 20px;
} */

/* .profile-friends-sub-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2vh 0;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--dark)
} */

/* .friends-sub-titles {
  font-size: 22px;
  font-weight: 100;
  letter-spacing: 2px;
}

.totals {
  padding-right: 2vh;
} */

.chevron {
  width: 25px;
}

.chevron:hover {
  cursor: pointer;
}

.sent-received {
  margin: 0 0 1vh 1vh;
  letter-spacing: 2px;
}

.friend-list-container {
  background-color: var(--darker);
  /* margin: 2vh 0; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.friend-list {
  overflow-y: scroll;
  scrollbar-width: thin;
  max-height: 26vh;
}

.venue-search-form {
  padding: 20px;
}

/* .border-bottom {
  border-bottom: 1px solid var(--darkest);
} */

/* .friend-search-input-wrapper {
  width: 100%;
  margin-bottom: 4vh;
  border-radius: 10px;
} */

/* .friend-search-input {
  border: none;
  width: 75%;
  border-radius: 10px 0 0 10px;
  padding: 5px 0 5px 5px;
  font-family: var(--primary-font);
}

.friend-search-button {
  width: 25%;
  color: var(--main-text);
  font-family: var(--primary-font);
  background-color: var(--button);
  border-radius: 0 10px 10px 0;
  padding: 5px;
}

.friend-search-button:hover {
  cursor: pointer;
} */

/* .blocked-list {
  overflow-y: scroll;
  max-height: 26vh;
} */



/* .names {
  margin: 1vh 2vh 1vh 2vh;
  padding: 1vh 0 1vh 2vh;
  border-bottom: 1px solid var(--darkest);
}

.name {
  letter-spacing: 2px;
  font-size: 18px;
} */

/* .friend-list-icons {
  width: 16px;
  color: var(--main-text);
  margin-right: 2vh;
}

.friend-list-icons:hover {
  cursor: pointer;
} */

.cancel {
  width: 25px;
  color: var(--cancel);
}

.cancel:hover {
  cursor: pointer;
}

.approve {
  width: 20px;
  color: var(--button);
  margin-right: 2vh;
}

.approve:hover {
  cursor: pointer;
}

.deny {
  width: 20px;
  color: var(--cancel);
  margin-right: 2vh;
}

.deny:hover {
  cursor: pointer;
}

/* VENUE LIST STYLES START */
/* .venue-list-wrapper {
  padding: 20px;
} */

/* .venue-name:hover {
  cursor: pointer;
} */

.venue-list-dates {
  font-family: var(--primary-font);
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 1.17em;
  color: var(--main-text);
  padding-bottom: 5px;
  text-align: center;
}

.venue-show-list-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: var(--background);
  padding-top: 18vh;
  min-height: 100vh;
}

.venue-heading {
  font-family: 'Kodchasan', sans-serif;
  letter-spacing: 5px;
  font-weight: 100;
  background-color: var(--darkest);
  padding: 5px;
  border-radius: 8px;
  text-align: center;
}

.venue-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  margin-bottom: 5vh;
}

.spacer {
  width: 50px;
}

.top-o-page {
  width: 50px;
  color: var(--dark);
  background-color: var(--main-text);
  position: fixed;
  top: 90vh;
  right: 5vh;
  border: 2px solid var(--main-text);
  border-radius: 50%;
  ;
}

.top-o-page:hover {
  cursor: pointer;
}

/* FOOTER STYLES START */
footer {
  height: 8vh;
  background-color: #383737;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* MEDIA QUERIES START */

/* PORTRAIT MODE MOBILE DEVICES START */
@media screen and (max-device-width: 767px) and (orientation: portrait) {

  /* HEADER STYLES START */
  .title-wrapper {
    width: 125px;
  }

  #title {
    display: none;
  }

  #title-mobile {
    display: block;
  }

  .utility-bar {
    width: 90vw;
  }

  /* DATE STYLES START */
  .date-wrapper {
    width: 100%;
  }

  #date {
    font-size: 14px;
  }

  .options {
    margin-right: 0;
  }

  /* SHOWCARD DATA STYLES START */
  #show-card-data {
    padding: 1vh 0;
  }

  /* #artists-link {
    display: block;
    width: 70vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  } */

  #at-venue {
    display: none;
  }

  #venue {
    display: block;
    /* width: 200px; */
    width: 70vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  #admin-buttons {
    display: none;
  }

  #divider {
    display: none;
  }

  /* RSVP STYLES START */
  .rsvp-wrapper {
    flex-direction: column;
  }

  .rsvp-counts {
    padding-left: 0;
    padding-top: 2vh;
  }

  /* PROFILE STYLES START */

  /* .profile-page-header h2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
  } */

  #expander {
    display: block;
  }

  .concert-friend-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    /* padding-top: 15vh; */
  }

  .profile-concerts-card {
    width: 90vw;
    min-height: 10vh;
    /* min-height: 30vh; */
  }

  /* .profile-concerts-card-header h2 {
    font-size: 16px;
  } */

  .profile-concerts-card-header p {
    font-size: 14px;
  }

  #profile-showcard-data {
    /* padding: 3vh 0; */
    /* align-items: center; */
  }

  #profile-showcard-data button {
    /* width: 85vw; */
  }

  .profile-friends-card {
    margin-top: 0;
    width: 90vw;
  }

  .profile-friends-card form div {
    width: 90%;
  }

  /* #show-card-venue-list-data {
    width: 85vw;
  } */

  /* .friend-list-container {
    margin-bottom: 3vh;
  } */
}

/* LANDSCAPE MODE DEVICES START */
@media screen and 
/* // changelog-start */
(max-device-width: 920px) and
/* (max-device-width: 767px) and */
/* // changelog-end */
(orientation: landscape) {
  .date-wrapper {
    width: 52%;
  }

  #show-card-contents {
    width: 98%;
  }

  /* #show-card-venue-list-data {
    width: 85vw;
  } */

  .rsvp-container {
    margin-bottom: 3vh;
  }

  footer {
    min-height: 16vh;
  }

  .top-o-page {
    top: 85vh;
  }
}

@media screen and (max-device-width: 914px) and (orientation: landscape) {
  .wrapperOptions {
    padding-top: 40vh;
  }
}