/* PROFILE STYLES START */
.profilePageWrapper {
    width: 100vw;
    /* min-height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background);
}

.profilePageHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12vh;
    padding-left: 12%;
    width: 100%;
    min-height: 10vh;
    gap: 3vw;
}

.profileUserTitle {
    font-size: 25px;
}

.profileFriendWrapper {
    width: 100%;
    /* min-height: 92vh; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.friendsLabelWrapper {
    display: flex;
    justify-content: center;
    /* align-items: end; */
    width: 100%;
    gap: 10px;
    /* background-color: rgba(123,123,321,0.4); */
}

.friendsLabelUserIcon {
    width: 20px;
    color: var(--main-text);
}

.profileConcertsWrapper {
    width: 100%;
    min-height: 92vh;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.profileConcerts {
    width: 60vw;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 3vh;
}

.profileConcertsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vh 0 2vh 2vh;
    border-bottom: 2px solid var(--dark);
    letter-spacing: 2px;
    font-size: 25px;
    width: 60vw;
}

#profileShowCardData {
    padding: 3vh 0 3vh 30px;
    width: 50vw;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

#profileShowCardData button {
    margin-top: 2vh;
    width: 150px;
    background-color: var(--button);
    border: none;
    border-radius: 10px;
    color: var(--main-text);
    font-family: var(--secondary-font);
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 16px;
    padding: 3px;
}

#profileShowCardData button:hover {
    cursor: pointer;
}

#artistsLink {
    font-size: 22px;
    font-weight: bold;
}

/* Tab Styles Start */
.tabButton {
    width: 50%;
    border: none;
    cursor: pointer;
    color: var(--darker);
    letter-spacing: 2px;
    font-size: 20px;
    background: none;
    padding: 10px 20px;
    position: relative;
    z-index: 1;
}

.tabButton:hover {
    color: var(--dark);
}

.activeProfileTab {
    color: var(--main-text) !important;
    font-weight: bold;
}

.tabContent {
    /* margin-top: 5vh; */
    padding: 3vh 0;
    font-size: 16px;
    color: var(--main-text);
    width: 100%;
    position: relative;
}

/* Skeleton Styles Start */
#skeletonShowCardWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vh;
    width: 90vw;
    margin-bottom: 3vh;
    font-size: 20px;
}

.skeletonShowCard {
    width: 50vw;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--darker);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skeletonImage {
    width: 100%;
    height: 140px;
    border-radius: 4px;
    background-color: var(--dark);
    animation: shimmer 2.5s infinite;
}

.skeletonTitle,
.skeletonLine {
    height: 16px;
    background-color: var(--dark);
    border-radius: 4px;
    animation: shimmer 2.5s infinite;
}

.skeletonTitle {
    width: 70%;
    margin-top: 10px;
}

.skeletonLine {
    width: 100%;
}

.skeletonLine:nth-child(4) {
    width: 90%;
}

.skeletonLine:nth-child(5) {
    width: 80%;
}

/* Shimmer Animation */
@keyframes shimmer {
    0% {
        background-position: -200px 0;
    }

    100% {
        background-position: 200px 0;
    }
}

.skeletonShowCard>div {
    background-image: linear-gradient(90deg,
            var(--dark) 0%,
            var(--darker) 50%,
            var(--dark) 100%);
    background-size: 200% 100%;
}

@media screen and (max-device-width: 767px) and (orientation: portrait) {
    .profilePageHeader {
        padding-left: 3%;
        min-height: 7vh;
    }

    .profileUserTitle {
        font-size: 20px;
    }

    .profileConcerts {
        width: 95vw;
    }

    #profileShowCardData {
        width: 95vw;
    }

    #skeletonShowCardWrapper {
        font-size: 16px;
    }

    .skeletonShowCard {
        width: 95vw;
    }
}