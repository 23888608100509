/* Tabs.module.css */
.tabsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tabs {
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75vw;
    height: 10vh;
    color: antiquewhite;
    border-bottom: 2px solid var(--darker);
    position: relative;
}

/* Slider underline for active tab */
.slider {
    position: absolute;
    bottom: -2px; /* Position the underline at the bottom */
    height: 2px;
    background-color: var(--main-text);
    transition: left 0.5s ease, width 0.5s ease;
    z-index: 0; /* Place slider below buttons */
    width: 25%;
}

.tabContent {
    padding: 3vh;
    font-size: 16px;
    color: var(--main-text);
    width: 100%;
    position: relative;
}

.fadeIn {
    opacity: 1;
}

.fadeOut {
    opacity: 0;
}

/* Slide Animations */
@keyframes slideInFromRight {
    from {
        transform: translateX(0);
        opacity: 0;
    }
    to {
        transform: translateX(-100%);
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(0);
        opacity: 0;
    }
    to {
        transform: translateX(100%);
        opacity: 1;
    }
}

.slideLeft {
    animation: slideInFromRight 0.5s ease forwards;
}

.slideRight {
    animation: slideInFromLeft 0.5s ease forwards;
}

@media screen and (max-device-width: 767px) and (orientation: portrait) {
    .tabs {
        width: 100vw;
        justify-content: space-around;
        height: 8vh;
    }

    .tabs h2 {
        font-size: 25px;
    }

    .tabContent {
        padding: 3vh 0 0 0;
    }
}
