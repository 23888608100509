.requestBlockWrapper {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    justify-content: center;
    width: 25vw;
}

.requestBlockForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 3vh;
    padding: 3vh 0;
}

.formInputWrapper {
    width: 75%;
    display: flex;
    justify-content: center;
}

.inputLabel {
    display: flex;
    width: 85%;
    height: 4vh;
    background-color: var(--darker);
    padding-left: 10px;
    border-radius: 5px;
}

.requestBlockIcon {
    width: 16px;
    color: var(--main-text);
    margin-right: 2vh;
}

.profileFriendsCardInput {
    border: none;
    width: 100%;
    padding: 5px 0;
    font-family: var(--primary-font);
    background-color: var(--darker);
    color: var(--main-text);
}

/* .profileFriendsCardInput {
    width: 100%;
    border-radius: 8px;
    padding: 5px 0 5px 5px;
    margin-top: 4vh;
} */

.profileFriendsCardInput:focus {
    border: none;
    outline: none;
}

.formDiv {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 2vh 8vh 0 8vh; */
    font-family: var(--secondary-font);
}

.formRequestButton {
    width: 100%;
    padding: 5px 0;
    background-color: var(--button);
    border: none;
    border-radius: 5px;
    color: var(--main-text);
    font-family: var(--secondary-font);
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 20px;
}

.formRequestButton:hover {
    cursor: pointer;
}

/* RequestBlock Styles Start */
.listWrapper {
    width: 25vw;
    background-color: var(--darker);
    /* margin: 2vh 0; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 6vh;
}

/* .pendingRequestHeader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2vh;
    padding-bottom: 2.2vh;
    border-bottom: 1px solid var(--dark)
} */

/* .subTitle {
    font-size: 22px;
    font-weight: 100;
    letter-spacing: 2px;
} */

/* .requestCount {
    padding-right: 2vh;
} */

/* Block Styles STart */
.alreadyDidTheThing {
    padding: 5px 0;
    width: 100%;
    background-color: var(--dark);
    border: none;
    border-radius: 5px;
    color: var(--main-text);
    font-family: var(--secondary-font);
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 20px;
    display: flex;
    justify-content: center;
}

.alreadyDidTheThing:hover {
    cursor: not-allowed;
}

#formBlockButton {
    padding: 5px 0;
    width: 100%;
    background-color: var(--cancel);
    border: none;
    border-radius: 5px;
    color: var(--main-text);
    font-family: var(--secondary-font);
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 20px;
}

#formBlockButton:hover {
    cursor: pointer;
}

.requestListScroll {
    overflow-y: scroll;
    scrollbar-width: thin;
    max-height: 48vh;
}

/* SCROLLBAR STYLES START */
.styledScrollbars {
    scrollbar-color: var(--darker) var(--dark)
}

.styledScrollbars::-webkit-scrollbar {
    background-color: var(--dark);
    width: 10px;
    border-radius: 0 10px 10px 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.styledScrollbars::-webkit-scrollbar-thumb {
    background-color: var(--darker);
    border-radius: 0 10px 10px 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.names {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--darkest);
    height: 6vh;
    padding: 0 5%;
    letter-spacing: 2px;
    font-size: 18px;
    width: 100%;
}

.names:hover {
    background-color: var(--darkest);
}

/* .names {
    margin: 1vh 2vh 1vh 2vh;
    padding: 1vh 0 1vh 2vh;
    border-bottom: 1px solid var(--darkest);
} */
/* // changelog-end */

.name {
    padding-left: 5%;
    letter-spacing: 2px;
    font-size: 18px;
}

.requestListOptionsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--darkest);
    background-color: var(--background);
    height: 6vh;
    width: 100%;
    padding: 0 5%;
    letter-spacing: 2px;
    font-size: 16px;
    color: var(--dark);
}

.requestStartWrapper {
    display: flex;
}

.cancelIcon {
    width: 2rem;
    /* width: 25px; */
    height: auto;
    color: var(--cancel);
}

.cancelIcon:hover {
    cursor: pointer;
}

.approveIcon {
    width: 1.75rem;
    height: auto;
    color: var(--button)
}

.approveIcon:hover {
    cursor: pointer;
}

.denyIcon {
    width: 1.5rem;
    height: auto;
    color: var(--cancel);
}

/* Media Queries Start */
@media screen and (max-device-width: 767px) and (orientation: portrait) {
    .listWrapper {
        width: 90vw;
    }
}
